import React, {useState, useEffect, Fragment, useRef} from 'react';
import styled from 'styled-components';
import Image from '../../src/Components/Image';
import * as Ant from 'antd';
import {Close} from '@styled-icons/material/Close';
import {AddCircle} from '@styled-icons/material/AddCircle';
const appConfig = require('../data.json');

function isBase64(src) {
  return src && src.indexOf && src.indexOf('base64,') > -1;
}

function Dialog({show, onClose, image, onEdit, onlyFile, sortable}) {
  const [link, setLink] = useState('');
  const [priority, setPriority] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (image) {
      setLink(image.link);
      setPriority(image.priority);
    } else {
      setLink('');
      setPriority(0);
    }
  }, [image]);

  return (
    <Ant.Modal
      visible={show}
      width={600}
      onCancel={onClose}
      footer={[
        <Ant.Spin spinning={loading}>
          <Ant.Space>
            <Ant.Button onClick={onClose} key="cancel">
              取消
            </Ant.Button>
            {onEdit && (
              <>
                <Ant.Upload
                  key="upload"
                  beforeUpload={(_file) => {
                    return false; //not upload immediately
                  }}
                  accept="image/*"
                  fileList={[]}
                  onChange={async (info) => {
                    setLoading(true);
                    try {
                      await onEdit({
                        ...image,
                        file: info.file,
                        id: image.id,
                        link,
                      });
                      onClose();
                    } catch (error) {}
                    setLoading(false);
                  }}>
                  <Ant.Button>重選圖片 </Ant.Button>
                </Ant.Upload>

                <Ant.Button
                  type="primary"
                  key="confirm"
                  onClick={async () => {
                    setLoading(true);
                    try {
                      await onEdit({
                        ...image,
                        id: image.id,
                        link,
                      });
                      onClose();
                    } catch (error) {}
                    setLoading(false);
                  }}>
                  更新
                </Ant.Button>
              </>
            )}
          </Ant.Space>
        </Ant.Spin>,
      ]}>
      {image ? (
        <Fragment>
          <Image
            src={image.image}
            host={
              isBase64(image.image) ? '' : `${appConfig.endpoint.mediaHost}`
            }
            css={`
              height: auto;
              width: 100%;
            `}
          />
          {!onlyFile && (
            <Fragment>
              <div style={{display: 'flex', alignItem: 'center', margin: 10}}>
                <h4 style={{marginRight: 10}}>連結</h4>
                <Ant.Input
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>

              {sortable && (
                <div style={{display: 'flex', alignItem: 'center', margin: 10}}>
                  <h4 style={{marginRight: 10}}>順序</h4>
                  <Ant.Input
                    // type="number"
                    placeholder="未完成"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}
    </Ant.Modal>
  );
}

function ImageEditor(props) {
  const {
    images,
    name,
    onDelete,
    onCreate,
    onEdit,
    css = '',
    onlyFile = false,
    sortable = false,
    // 限制圖片數量，從帶進來的數字來判斷
    imageLimit,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const file = useRef();

  // 確認圖片數量是否達上限
  const imageCount = images?.filter((image) => image.name === name).length;
  const canCreate = imageLimit ? imageCount < imageLimit : true;

  return (
    <Wrapper css={css}>
      <div className="images-container">
        {images
          .filter((image) => image.image && image.name === name)
          .sort((a, b) => (sortable ? a.priority - b.priority : 0))
          .map((image, idx) => {
            return (
              <div key={idx} className="image-container">
                <Image
                  src={image.image}
                  host={
                    isBase64(image.image)
                      ? ''
                      : `${appConfig.endpoint.mediaHost}`
                  }
                  background={isBase64(image.image) ? false : true}
                  css={
                    `
                    height: 100%;
                    width: 100%;
                    border: solid 1px #d9d9d9;
                    cursor: pointer;
                  ` + (isBase64(image.image) ? 'object-fit: cover;' : '')
                  }
                  onClick={() => {
                    setSelectedImage(image);
                    setShowDialog(true);
                  }}
                />
                {onDelete && (
                  <div className="delete" onClick={() => onDelete(image)}>
                    <Close size={24} color="white" />
                  </div>
                )}
              </div>
            );
          })}
        {onCreate && canCreate && (
          <Ant.Upload
            className="image-uploader"
            accept="image/*"
            beforeUpload={(_file) => {
              file.current = _file;
              return false; //not upload immediately
            }}
            fileList={[]}
            onChange={async (info) => {
              let resp = await onCreate({file: file.current, name});
            }}>
            <AddCircle size={100} color="#fff" />
          </Ant.Upload>
        )}
      </div>
      <Dialog
        show={showDialog}
        onClose={() => {
          setShowDialog(false);
          setSelectedImage(null);
        }}
        image={selectedImage}
        onEdit={onEdit}
        onlyFile={onlyFile}
        sortable={sortable}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .images-container {
    display: flex;
    flex-wrap: wrap;

    & > .image-container {
      position: relative;
      width: 200px;
      height: 200px;
      margin-right: 5px;
      margin-bottom: 5px;

      & .delete {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: tomato;
        cursor: pointer;
      }

      &:hover,
      :active {
        opacity: 0.5;
      }
    }
  }

  & .image-uploader {
    &:hover,
    :active {
      opacity: 0.5;
    }
  }

  & .image-uploader > .ant-upload {
    width: 200px;
    height: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    border: solid 1px #d9d9d9;
    cursor: pointer;
  }

  & button {
  }

  ${(props) => props.css}
`;

export default ImageEditor;
