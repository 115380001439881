import React from 'react';
import ImageEditor from './ImageEditor';
import UploadFileButton from './UploadFileButton';
import Row from '../Widgets/Row';
import Hint from '../Widgets/Hint';

function SpecImageEditor({
  item,
  name,
  onUpdate,
  onDelete,
  imageNumLimit = 1,
  needed,
}) {
  return (
    <>
      <Row style={{marginBottom: '15px', alignItems: 'flex-start'}}>
        <h4 style={{width: 'fit-content', alignSelf: 'flex-start'}}>
          {needed ? `*${name}` : name}
        </h4>
        <Hint type="info"></Hint>
        <Row
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          {item && Array.isArray(item)
            ? item.length < imageNumLimit && (
                <UploadFileButton
                  onUpload={(file) => {
                    onUpdate(file.url);
                  }}
                  onUpdate={() => {}}
                />
              )
            : !item && (
                <UploadFileButton
                  file={item}
                  onUpload={(file) => {
                    onUpdate(file.url);
                  }}
                  onUpdate={() => {}}
                />
              )}
          {item && Array.isArray(item) ? (
            <Row style={{gap: '8px'}}>
              {item.map((image, idx) => {
                return (
                  <ImageEditor
                    images={[{image, name}]}
                    name={name}
                    onlyFile
                    onDelete={() => {
                      onDelete(idx);
                    }}
                  />
                );
              })}
            </Row>
          ) : (
            <ImageEditor
              images={[{image: item, name}]}
              name={name}
              onlyFile
              onDelete={() => {
                onDelete();
              }}
            />
          )}
        </Row>
      </Row>
    </>
  );
}

export default SpecImageEditor;
