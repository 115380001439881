import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import PricingTypes from '../../pricingTypes';
import ImageEditor from '../../Components/ImageEditor';
import FileUploader from '../../Components/FileUploader';
import BackButton from '../../Components/BackButton';
import SpecImageEditor from '../../Components/SpecImageEditor';
import {errorHandler, ErrProduct} from '../../errors';
import Button from '../../Widgets/Button';
import Block from '../../Widgets/Block';
import Row from '../../Widgets/Row';
import Tabs from '../../Widgets/Tabs';

import {isExceedNameLengthLimit, isNotEmpty} from '../../Utils/validate';

const appConfig = require('../../data.json');
const qs = require('query-string');

let pricingTypeOptions = PricingTypes.pricingTypes.filter(
  (x) => x !== 'custom',
);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function ImageBlock({record, images, setImages}) {
  const {id} = record;
  const [actions] = useOutlet('actions');

  const getImages = useCallback(async () => {
    if (id) {
      let resp = await actions.getProductImages(id);
      setImages(resp);
    }
  }, [id, actions]);

  const create = useCallback(
    async ({file, name}) => {
      actions.setLoading(true);
      try {
        await actions.createProductImage({
          image: file,
          link: '',
          product: id, //product id
          name,
        });
        await getImages();
      } catch (err) {
        errorHandler(err, '新增圖片錯誤');
      }
      actions.setLoading(false);
    },
    [actions, id, getImages],
  );

  const edit = useCallback(
    async ({file, id, ...arg}) => {
      actions.setLoading(true);
      try {
        await actions.editProductImage({
          ...arg,
          image: file,
          id, //image id
        });
        await getImages();
      } catch (err) {
        errorHandler(err, '更新圖片錯誤');
      }
      actions.setLoading(false);
    },
    [actions, getImages],
  );

  const remove = useCallback(
    async ({id}) => {
      actions.setLoading(true);
      try {
        await actions.deleteProductImage(id);
        await getImages();
      } catch (err) {
        errorHandler(err, '刪除圖片錯誤');
      }
      actions.setLoading(false);
    },
    [actions, getImages],
  );

  return (
    <Block style={{marginBottom: 20}}>
      <h2>商品圖片</h2>
      <div className="divider" />
      <div className="content">
        <div className="row">
          <h4 style={{alignSelf: 'flex-start'}}>詳細頁圖片</h4>

          <ImageEditor
            images={images}
            name="top"
            onCreate={create}
            onDelete={remove}
            onEdit={edit}
          />
        </div>
        <div className="row">
          <h4 style={{alignSelf: 'flex-start'}}>產品規格</h4>
          <ImageEditor
            images={images}
            name="spec"
            onCreate={create}
            onDelete={remove}
            onEdit={edit}
          />
        </div>
        <div className="row">
          <h4 style={{alignSelf: 'flex-start'}}>產品介紹</h4>
          <ImageEditor
            images={images}
            name="intro"
            onCreate={create}
            onDelete={remove}
            onEdit={edit}
          />
        </div>
        <div className="row">
          <h4 style={{alignSelf: 'flex-start'}}>完稿須知</h4>
          <ImageEditor
            images={images}
            name="notice"
            onCreate={create}
            onDelete={remove}
            onEdit={edit}
          />
        </div>
      </div>
    </Block>
  );
}

function FileBlock({record, files, setFiles}) {
  const {id} = record;
  const [actions] = useOutlet('actions');

  const getFiles = useCallback(async () => {
    let files = await actions.getProductFiles(id);
    setFiles(files);
  }, [id, actions]);

  const create = async ({file, name}) => {
    actions.setLoading(true);
    try {
      await actions.createProductFile({
        file,
        link: '',
        product: id, //product id
        name,
      });
      await getFiles();
    } catch (err) {
      errorHandler(err, '新增檔案錯誤');
    }
    actions.setLoading(false);
  };

  const edit = async ({file, id, ...arg}) => {
    actions.setLoading(true);
    try {
      await actions.editProductFile({
        ...arg,
        file,
        id, //file id
      });
      await getFiles();
    } catch (err) {
      errorHandler(err, '更新檔案錯誤');
    }
    actions.setLoading(false);
  };

  const remove = async ({id}) => {
    actions.setLoading(true);
    try {
      await actions.deleteProductFile(id);
      await getFiles();
    } catch (err) {
      errorHandler(err, '刪除檔案錯誤');
    }
    actions.setLoading(false);
  };

  return (
    <Block>
      <h2>商品檔案</h2>
      <div className="divider" />
      <div className="content">
        <div className="row">
          <h4 style={{alignSelf: 'flex-start'}}>刀模下載</h4>

          <FileUploader
            files={files}
            name="template"
            onCreate={create}
            onDelete={remove}
            onEdit={edit}
          />
        </div>
      </div>
    </Block>
  );
}

export default function ProductDetail(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const {Input, Collapse} = Ant;
  const {Panel} = Ant.Collapse;
  const [record, setRecord] = useState({
    name: '',
    file_upload: true,
    is_on_shelf: false,
    is_out_stock: false,
    is_urgent: false,
    is_proof: false,
    is_editable: false,
    description: '',
    label: '合版名片',
    promo_zone: '',
    hashtag: '名片',
    tag: 'NEW',
    related_product: null,
    original_price: 0,
    price: 0,
    image: '',
    calc_type: pricingTypeOptions[0],
    design_spec: null,
  });
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [designSpec, setDesignSpec] = useState({});
  const [files, setFiles] = useState([]);
  const [chosenTab, setChosenTab] = useState('1');

  const [actions] = useOutlet('actions');
  const isEdit = !!id;

  const initDesignSpec = {
    created_at: '',
    updated_at: '',
    id: null,
    product: null,
    die_cut_type: 'default',
    editor_options: [],
    is_white_ink: false,
  };

  const addSpecOrEditSpec = (data) => {
    // 線上編輯未勾選，不要新增或編輯商品設計規格
    if (!record.is_editable) return;

    const originalData = record.design_spec;
    if (originalData) {
      return actions.editProductDesignSpec(data);
    } else {
      return actions.addProductDesignSpec(data, record.id);
    }
  };

  const createOrEdit = () => {
    if (isEdit) {
      return edit;
    }
    return create;
  };

  const _setRecord = useCallback((obj) =>
    setRecord((prev) => ({...prev, ...obj})),
  );

  const getRecord = useCallback(async () => {
    if (id) {
      actions.setLoading(true);
      try {
        let resp = await actions.getProduct(id);
        setRecord({
          id: id,
          name: resp.name,
          file_upload: resp.file_upload,
          is_on_shelf: resp.is_on_shelf,
          is_out_stock: resp.is_out_stock,
          is_urgent: resp.is_urgent,
          is_proof: resp.is_proof,
          is_editable: resp.is_editable,
          description: resp.description,
          label: resp.label,
          promo_zone: resp.promo_zone,
          hashtag: resp.hashtag,
          tag: resp.tag,
          related_product: resp.related_product,
          original_price: resp.original_price,
          price: resp.price,
          image: resp.image,
          calc_type: resp.calc_type,
          design_spec: resp.design_spec,
        });
        setImages(resp.images);
        setFiles(resp.files);
        setDesignSpec(resp.design_spec || initDesignSpec);

        // this.setState({ record, images: _record.images, files: _record.files });
      } catch (ex) {
        errorHandler(ex, '取得商品詳細錯誤');
      }
      actions.setLoading(false);
    }
  }, [id, actions]);

  useEffect(() => {
    async function getProducts() {
      let products = await actions.getProducts({no_page: true});
      products = products.map((x) => ({id: x.id, name: x.name}));
      products.unshift({id: null, name: '無'});
      setProducts(products);
    }

    (async () => {
      await Promise.all([getProducts(), getRecord()]);
    })();
  }, [getRecord]);

  function valid() {
    const limit = 30;
    if (!record.name) {
      throw new ErrProduct('名稱為必填');
    }
    if (
      isExceedNameLengthLimit({
        value: record.name,
        limit,
      })
    ) {
      throw new ErrProduct(`商品名稱長度不可超過${limit}個全形字元`);
    }
    if (record.is_editable) {
      if (
        designSpec.die_cut_type === 'default' &&
        designSpec.editor_options.length === 0
      ) {
        throw new ErrProduct('規格為必填');
      }
      designSpec.editor_options.forEach((option) => {
        if (!isNotEmpty(option.editor_id)) {
          throw new ErrProduct('刀模圖片識別碼為必填');
        }
        if (!isNotEmpty(option.mask_image)) {
          throw new ErrProduct('遮罩圖片為必填');
        }
        if (!isNotEmpty(option.die_cut_image)) {
          throw new ErrProduct('刀模圖片為必填');
        }
        if (!option.preview_images.length) {
          throw new ErrProduct('預覽圖片為必填');
        }
      });
    }
  }

  async function create() {
    actions.setLoading(true);
    try {
      valid();
      let resp = await actions.addProduct(record);
      Ant.message.success('新增成功');
      getRecord();
    } catch (err) {
      errorHandler(err, '新增發生錯誤');
    }
    actions.setLoading(false);
  }

  async function edit() {
    actions.setLoading(true);
    try {
      valid();
      await actions.editProduct(record);
      await addSpecOrEditSpec(designSpec);
      Ant.message.success('編輯成功');
      getRecord();
    } catch (err) {
      errorHandler(err, '編輯發生錯誤');
    }
    actions.setLoading(false);
  }

  if (!record) {
    return null;
  }

  // 新增規格
  const addEditorOption = () => {
    const newEditorOptions = designSpec.editor_options || [];
    newEditorOptions.push({
      editor_id: `新增規格${newEditorOptions.length + 1}`,
      mask_image: null,
      die_cut_image: null,
      preview_images: [],
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 編輯規格識別碼
  const editEditorId = (idx, value) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions[idx].editor_id = value;
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 刪除規格
  const deleteEditorOption = (editor_id) => {
    const newEditorOptions = designSpec.editor_options.filter(
      (option) => option.editor_id !== editor_id,
    );
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 新增 editor option 內對應 editor_id 的 type 圖片
  const addEditorOptionImage = ({editorId, type, value}) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions.forEach((option) => {
      if (option.editor_id === editorId) {
        if (type === 'preview_images') {
          option[type].push(value);
        } else {
          option[type] = value;
        }
      }
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  // 刪除 editor option 內對應 editor_id 的 type 圖片
  const deleteEditorOptionImage = ({editorId, type, index}) => {
    const newEditorOptions = [...designSpec.editor_options];
    newEditorOptions.forEach((option) => {
      if (option.editor_id === editorId) {
        if (type === 'preview_images') {
          option[type].splice(index, 1);
        } else {
          option[type] = null;
        }
      }
    });
    setDesignSpec({
      ...designSpec,
      editor_options: newEditorOptions,
    });
  };

  const editedItems = [
    {
      key: '1',
      label: '商品編輯器',
      children: (
        <div className="content">
          <div
            className="row"
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}>
            <Ant.Radio.Group
              value={designSpec.die_cut_type}
              // 目前只有一個選項
              // onChange={(e) => {
              //   setDesignSpec({
              //     ...designSpec,
              //     die_cut_type: e.target.value,
              //   });
              // }}
            >
              <Ant.Radio value="default">預設刀模</Ant.Radio>
              <Ant.Radio value="custom" disabled>
                客製化刀模
              </Ant.Radio>
            </Ant.Radio.Group>
            <p style={{color: '#9B9B9B'}}>
              後台人員定義刀模，關閉前台編輯刀模功能
            </p>
            <Button onClick={addEditorOption}>新增規格</Button>
          </div>
          <Collapse defaultActiveKey={['0']} ghost>
            {designSpec &&
              designSpec.editor_options &&
              designSpec.editor_options.map((option, index) => (
                <Panel header={option.editor_id} key={index}>
                  <Row
                    style={{
                      marginBottom: '15px',
                      alignItems: 'flex-start',
                      gap: '5px',
                    }}>
                    <h4 style={{width: 'fit-content', alignSelf: 'flex-start'}}>
                      *刀模圖片識別碼
                    </h4>
                    <Input
                      placeholder={'請輸入刀模圖片識別碼 ' + (index + 1)}
                      value={option.editor_id}
                      onChange={(e) => editEditorId(index, e.target.value)}
                    />
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        if (window.confirm('確定要刪除？')) {
                          deleteEditorOption(option.editor_id);
                        }
                      }}>
                      刪除
                    </Button>
                  </Row>
                  <SpecImageEditor
                    item={option.mask_image}
                    name="遮罩圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'mask_image',
                        value,
                      })
                    }
                    onDelete={() =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'mask_image',
                      })
                    }
                    needed
                  />
                  <SpecImageEditor
                    item={option.die_cut_image}
                    name="刀模圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'die_cut_image',
                        value,
                      })
                    }
                    onDelete={() =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'die_cut_image',
                      })
                    }
                    needed
                  />
                  <SpecImageEditor
                    item={option.preview_images}
                    name="預覽圖片"
                    onUpdate={(value) =>
                      addEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'preview_images',
                        value,
                      })
                    }
                    onDelete={(index) =>
                      deleteEditorOptionImage({
                        editorId: option.editor_id,
                        type: 'preview_images',
                        index,
                      })
                    }
                    imageNumLimit={3}
                    needed
                  />
                  {/* <SpecImageEditor item={option} spec={designSpec} /> */}
                </Panel>
              ))}
          </Collapse>
        </div>
      ),
    },
    {
      key: '2',
      label: '其他設定',
      children: (
        <div className="content">
          <Row style={{gap: '8px'}}>
            白墨編輯
            <Ant.Checkbox
              checked={designSpec.is_white_ink}
              onChange={(e) =>
                setDesignSpec({
                  ...designSpec,
                  is_white_ink: e.target.checked,
                })
              }
            />
          </Row>
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecord}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>
      <Block style={{marginBottom: 20}}>
        <h2>{!isEdit ? '新增商品' : '#' + record.id}</h2>
        <div className="divider" />
        <div>
          <Ant.Row gutter={20}>
            <Ant.Col xs={24} lg={12}>
              <div className="row">
                <h4>編號</h4>
                <div>{record.id}</div>
              </div>
              <div className="row">
                <h4>名稱</h4>
                <Ant.Input
                  value={record.name}
                  onChange={(e) => _setRecord({name: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>需上傳檔案</h4>
                <label>
                  <Ant.Checkbox
                    checked={record.file_upload}
                    onChange={(e) =>
                      _setRecord({file_upload: e.target.checked})
                    }
                  />{' '}
                  (非零售商品)
                </label>
              </div>

              <div className="row">
                <h4>上架</h4>
                <label>
                  <Ant.Checkbox
                    checked={record.is_on_shelf}
                    onChange={(e) =>
                      _setRecord({is_on_shelf: e.target.checked})
                    }
                  />
                </label>
              </div>

              <div className="row">
                <h4>售完</h4>
                <label>
                  <Ant.Checkbox
                    checked={record.is_out_stock}
                    onChange={(e) =>
                      _setRecord({is_out_stock: e.target.checked})
                    }
                  />
                </label>
              </div>

              <div className="row">
                <h4>是否為急件商品</h4>
                <label>
                  <Ant.Checkbox
                    checked={record.is_urgent}
                    onChange={(e) => _setRecord({is_urgent: e.target.checked})}
                  />
                </label>
              </div>

              <div className="row">
                <h4>是否為打樣商品</h4>
                <label>
                  <Ant.Checkbox
                    checked={record.is_proof}
                    onChange={(e) => _setRecord({is_proof: e.target.checked})}
                  />
                </label>
              </div>

              <div className="row">
                <h4>商品說明</h4>
                <Ant.Input.TextArea
                  value={record.description}
                  onChange={(e) => _setRecord({description: e.target.value})}
                />
              </div>
            </Ant.Col>
            <Ant.Col xs={24} lg={12}>
              <div className="row">
                <h4>分類</h4>
                <Ant.Input
                  value={record.label}
                  onChange={(e) => _setRecord({label: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>Prome Zone</h4>
                <Ant.Input
                  value={record.promo_zone}
                  onChange={(e) => _setRecord({promo_zone: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>HashTag #</h4>
                <Ant.Input
                  value={record.hashtag}
                  onChange={(e) => _setRecord({hashtag: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>標籤</h4>
                <Ant.Input
                  value={record.tag}
                  onChange={(e) => _setRecord({tag: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>規格種類</h4>
                <Ant.Select
                  value={record.calc_type}
                  onChange={(value) => _setRecord({calc_type: value})}>
                  {pricingTypeOptions.map((p) => (
                    <Ant.Select.Option key={p} value={p}>
                      {p}
                    </Ant.Select.Option>
                  ))}
                </Ant.Select>
              </div>
              <div className="row">
                <h4>參考商品</h4>
                <Ant.Select
                  value={record.related_product}
                  onChange={(value) => _setRecord({related_product: value})}>
                  {products.map((p) => (
                    <Ant.Select.Option key={p.id} value={p.id}>
                      {p.name}
                    </Ant.Select.Option>
                  ))}
                </Ant.Select>
              </div>
            </Ant.Col>
          </Ant.Row>
        </div>
        <div className="divider" />
        <div>
          <Ant.Row gutter={20}>
            <Ant.Col xs={24} lg={12}>
              <div className="row">
                <h4>原價</h4>
                <Ant.Input
                  value={record.original_price}
                  type="number"
                  onChange={(e) => _setRecord({original_price: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>價格</h4>
                <Ant.Input
                  value={record.price}
                  type="number"
                  onChange={(e) => _setRecord({price: e.target.value})}
                />
              </div>
            </Ant.Col>
            <Ant.Col xs={24} lg={12}>
              <div className="row">
                <h4 style={{alignSelf: 'flex-start'}}>縮圖</h4>
                <ImageEditor
                  images={[
                    {image: record.imageBase64 || record.image, name: ''},
                  ]}
                  name=""
                  onlyFile={true}
                  onCreate={
                    record.image
                      ? null
                      : async ({file}) => {
                          actions.setLoading(true);

                          //preview img
                          let base64 = await getBase64(file);
                          actions.setLoading(false);

                          _setRecord({imageBase64: base64, image: file});
                        }
                  }
                  onEdit={async ({file}) => {
                    //preview img
                    let base64 = await getBase64(file);
                    _setRecord({imageBase64: base64, image: file});
                  }}
                  onDelete={({file}) =>
                    _setRecord({
                      image: null,
                      imageBase64: null,
                    })
                  }
                />
              </div>
            </Ant.Col>
          </Ant.Row>
        </div>
        <div className="divider" />
        <h2>稿件線上編輯</h2>
        <div className="content">
          <div className="row">
            <label>
              <Ant.Checkbox
                checked={record.is_editable}
                onChange={(e) => _setRecord({is_editable: e.target.checked})}
                style={{
                  marginRight: '8px',
                }}
              />{' '}
              線上編輯
            </label>{' '}
          </div>
          {record.is_editable && (
            <>
              <Tabs
                value={chosenTab}
                onChange={(value) => {
                  setChosenTab(value);
                }}
                options={editedItems.map((item) => ({
                  label: item.label,
                  value: item.key,
                }))}
              />
              <div style={{marginTop: '20px'}}>
                {editedItems.map((item) => {
                  return (
                    <div
                      key={item.key}
                      style={{
                        display: chosenTab === item.key ? 'block' : 'none',
                      }}>
                      {item.children}
                    </div>
                  );
                })}
              </div>

              {/* <div style={{marginTop: '20px'}}>
                        {editedItems.map((item) => {
                          return (
                            <div
                              key={item.key}
                              style={{
                                display: chosenTab === item.key ? 'block' : 'none',
                              }}>
                              {item.children}
                            </div>
                          );
                        })}
                      </div> */}
            </>
          )}
        </div>
        {/* <SpecImageEditor
          editable={record.is_editable}
          onChangeEditable={(value) => _setRecord({is_editable: value})}
          spec={designSpec}
          onChangeSpec={(value) => setDesignSpec(value)}
        />{' '} */}
        <div className="divider" />
        <div className="row">
          <div style={{flex: 1}} />
          <Button type="primary" onClick={createOrEdit()}>
            儲存
          </Button>
        </div>
      </Block>

      {isEdit && (
        <React.Fragment>
          <ImageBlock record={record} images={images} setImages={setImages} />
          <FileBlock record={record} files={files} setFiles={setFiles} />
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
