import {navigate} from 'gatsby';
import {getNewOutlet} from 'reconnect.js';
import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import {ErrHistory, ErrLogin} from './errors';
import decodeJWTPayload from './Utils/JwtDecode.js';
import api from './ApiProxy';
import {getAccessToken} from './TokenService.js';

const appConfig = require('./data.json');
const {zip3} = require('./taiwan');

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? '?' : '&'}${key}=${params[key]}`;
        return e;
      }, '');
  }
  return query;
}

const SpinnerOutlet = getNewOutlet('loading', false, {autoDelete: false});
const UserOutlet = getNewOutlet('user', null, {autoDelete: false});
const ActionOutlet = getNewOutlet('actions', null, {autoDelete: false});
const JwtTokenOutlet = getNewOutlet('jwtToken', null, {autoDelete: false});
const SidebarOutlet = getNewOutlet('sidebar', null, {autoDelete: false});
const AutoLoginProcessingOutlet = getNewOutlet('autoLoginProcessing', true, {
  autoDelete: false,
});
const isManualLoginOutlet = getNewOutlet('isManualLogin', false, {
  autoDelete: false,
});

const API_PREFIX = '/api/v1/admin';

const Actions = {
  setToken: (token) => {
    api.setToken(token);
  },
  getMicroServiceToken: async () => {
    try {
      let {token} = await api.get({
        path: `${API_PREFIX}/user/jwt`,
      });
      JwtTokenOutlet.update(token);
    } catch (error) {}
  },
  setLoading: (loading) => SpinnerOutlet.update(loading),

  navigate: (nextRoute, options = {loading: true}) => {
    if (options.loading) {
      SpinnerOutlet.update(true);
    }
    navigate(nextRoute);
  },

  getHistories: async (data) => {
    const token = JwtTokenOutlet.getValue();
    // if(!token) {
    //   throw new ErrHistory('找不到憑證，請再試一次');
    // }
    if (token) {
      return api.post({
        path: `${appConfig.endpoint.revJstorageHost}/document/history/find?token=${token}`,
        withHost: true,
        data,
      });
    } else {
      return {
        results: [],
        total: 0,
      };
    }
  },

  getCustomerServiceInfo: async (key) => {
    let data = {
      query: {
        key,
      },
    };
    return api.post({
      path: `${appConfig.endpoint.revJstorageHost}/document/misc/find-one?client_id=${appConfig.client}`,
      withHost: true,
      data: JSON.stringify(data),
    });
  },
  updateCustomerServiceInfo: async ({key, content, token}) => {
    let result = {
      query: {
        key,
      },
      data: {
        content,
        html: '',
      },
    };
    return api.post({
      path: `${appConfig.endpoint.revJstorageHost}/document/misc/update?token=${token}`,
      withHost: true,
      data: result,
    });
  },

  getSuppliers: async (params) => {
    return api.get({
      path: `${API_PREFIX}/supplier`,
    });
  },

  login: async function ({username, password}) {
    try {
      await this.getJwtToken({username, password});
      this.getMicroServiceToken();
      await this.fetchSidebar();
      navigate(`/`);
    } catch (err) {
      api.setToken(null);
      this.clearLocalStorage();
      console.warn(err);
      throw err;
    }
  },
  getJwtToken: async function ({username, password}) {
    try {
      let {access, refresh} = await api.post({
        path: `${API_PREFIX}/login`,
        data: {username, password},
        secure: false,
      });
      const {exp: accessTokenExpiry} = decodeJWTPayload(access);
      const {exp: refreshTokenExpiry} = decodeJWTPayload(refresh);
      api.setToken(access, accessTokenExpiry);
      isManualLoginOutlet.update(true);
      this.updateUser(access);
      AsyncStorage.setItem('accessToken', access);
      AsyncStorage.setItem('refreshToken', refresh);
      AsyncStorage.setItem('accessTokenExpiry', accessTokenExpiry);
      AsyncStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
    } catch (err) {
      this.clearLocalStorage();
      console.warn(err);
      throw err;
    }
  },
  autoLogin: async function () {
    const isManualLogin = isManualLoginOutlet.getValue();
    try {
      if (isManualLogin) return;
      const refreshToken = await AsyncStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new ErrLogin('找不到有效的刷新令牌');
      }
      let access = await getAccessToken();
      if (access) {
        this.updateUser(access);
        await this.fetchSidebar();
        this.getMicroServiceToken();
      } else {
        throw new ErrLogin('找不到憑證');
      }
    } catch (err) {
      api.setToken(null);
      this.clearLocalStorage();
      console.warn(err);
      throw err;
    }
  },
  updateUser(token) {
    const payload = decodeJWTPayload(token);
    UserOutlet.update({
      ...payload,
      user: {
        username: payload.username,
        isAmountVisible: payload.is_amount_visible
          ? payload.is_amount_visible
          : false,
      },
      staff_type: 'staff',
      user_type: 'normal',
    });
  },
  async fetchSidebar() {
    try {
      const transformData = (data) => {
        return data.map((item) => {
          return {
            id: item.id,
            name: item.uri.replace(/\/$/, ''),
            label: item.title,
            ...(item.children &&
              item.children.length > 0 && {
                entries: item.children.map((child) => ({
                  id: child.id,
                  name: child.uri.replace(/\/$/, ''),
                  label: child.title,
                })),
              }),
          };
        });
      };
      let data = await api.get({
        path: `${API_PREFIX}/sidebar`,
      });
      SidebarOutlet.update(
        transformData([
          ...data,
          {
            uri: 'logout/',
            title: '登出',
            type: 'function',
          },
        ]),
      );
    } catch (err) {
      console.warn(err);
      throw err;
    }
  },
  async logout() {
    api.setToken(null);
    this.clearLocalStorage();
    UserOutlet.update(null);
    JwtTokenOutlet.update(null);
    AutoLoginProcessingOutlet.update(true);
  },

  autoLoginFinish: async () => {
    AutoLoginProcessingOutlet.update(false);
  },

  clearLocalStorage: () => {
    AsyncStorage.removeItem('id');
    AsyncStorage.removeItem('accessToken');
    AsyncStorage.removeItem('refreshToken');
    AsyncStorage.removeItem('accessTokenExpiry');
    AsyncStorage.removeItem('refreshTokenExpiry');
  },

  //#region statistic
  getStatistics: async (params) => {
    let query = transToQueryStr(params);
    /*
    from,
    to,
    */
    return api.get({
      path: `${API_PREFIX}/order/report${query}`,
    });
  },

  getSalesReport: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${appConfig.endpoint.productSpecUploadUrl}/sales/list${query}`,
      withHost: true,
    });
  },
  //#endregion statistic

  //#region order
  getOrders: async (params) => {
    /*
    ordering, //sort
    order_type,
    payment_status,
    payment_type,
    payment_condition, //有效訂單（for review order list）
    is_item_accepted, //for production step order list
    is_item_manufacture, //for production step order list
    buyer_type,
    delivery_status,
    is_credits_order,
    from_date,
    to_date,
    buyer,
    search, //display_id and receiver_name and email,
    offset,
    limit,
    no_page = false,
    display_state
    my_assignment
    period_order,
    */
    params = {
      ...params,
      ordering: params.ordering || '-created',
    };
    let query = transToQueryStr(params);

    // must have ordering
    // ordering = `amount` or `created` or ``
    return api.get({
      path: `${API_PREFIX}/order${query}`,
    });
  },

  getOrder: async (id) =>
    api.get({
      path: `${API_PREFIX}/order/${id}`,
    }),

  createExtraOrder: async (data) =>
    api.post({
      path: `${API_PREFIX}/order/extra`,
      data,
    }),

  editOrder: async ({id, ...params}) =>
    api.put({
      path: `${API_PREFIX}/order/${id}`,
      data: params,
    }),

  voidOrder: async ({id, void_reason}) => {
    return api.post({
      path: `${API_PREFIX}/order/${id}/void`,
      data: {
        void_reason,
      },
    });
  },

  createCustomOrder: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order/custom`,
      data,
    });
  },

  calcCustomOrder: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order/custom/calc`,
      data,
    });
  },

  //#endregion order

  //#region order_item
  getOrderItems: async (order_id) => {
    return api.get({
      // TOOD::
      path: `${API_PREFIX}/order-item-review?order=${order_id}`,
    });
  },

  editOrderItem: async (data) => {
    return api.put({
      path: `${API_PREFIX}/order-item-review/${data.id}`,
      data,
    });
  },

  addAttatchment: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order-item-review/attachment`,
      data,
    });
  },

  //#endregion order_item

  //#region logistic
  getLogistics: async (params) => {
    params.ordering = '-updated';
    let query = transToQueryStr(params);

    if (params.order_voided === false) {
      query += '&order_voided=false';
    }

    return api.get({
      path: `${API_PREFIX}/logistics` + query,
    });
  },

  editLogistic: async ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/logistics/${id}`,
      data,
    });
  },

  createLogistic: async (data) => {
    return api.post({
      path: `${API_PREFIX}/logistics`,
      data,
    });
  },
  //#endregion logistic

  //#region invoice
  getInvoices: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/invoice` + query,
    });
  },

  getInvoice: async (id) => {
    return api.get({
      path: `${API_PREFIX}/invoice/${id}`,
    });
  },

  voidInvoice: async (data) => {
    return api.post({
      path: `${API_PREFIX}/invoice/void`,
      data,
    });
  },

  createInvoice: async ({type, params: data}) => {
    /*
      NORMAL: 0,
      EMTPY_INVOICE: 1
      */
    if (type == 0) {
      // NORMAL
      return api.post({
        path: `${API_PREFIX}/order/invoice`,
        data,
      });
    } else {
      // EMTPY_INVOICE
      return api.post({
        path: `${API_PREFIX}/invoice`,
        data,
      });
    }
  },

  editInvoiceAfterTreatmentState: async (data) => {
    return api.post({
      path: `${API_PREFIX}/invoice/after-threatment`,
      secure: true,
      data,
    });
  },

  //#endregion invoice

  //#region refund
  getRefunds: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      secure: true,
      path: `${API_PREFIX}/refund` + query,
    });
  },

  createRefund: async (data) => {
    return api.post({
      path: `${API_PREFIX}/refund`,
      data,
    });
  },

  editRefund: async (data) => {
    return api.put({
      path: `${API_PREFIX}/refund/${data.id}`,
      data,
    });
  },

  refundToPaymentProvider: async (id) => {
    return api.post({
      path: `${API_PREFIX}/refund/${id}`,
    });
  },
  //#endregion refund

  //#region return_app
  getReturnApps: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/return-app` + query,
    });
  },

  getReturnApp: async (id) => {
    return api.get({
      path: `${API_PREFIX}/return-app/${id}`,
    });
  },

  createReturnApp: async (params) => {
    let formData = new FormData();
    for (let key in params) {
      if (params[key] !== undefined && params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    return api.formPost({
      formData,
      path: `${API_PREFIX}/return-app`,
    });
  },

  editReturnApp: async (params) => {
    let formData = new FormData();
    for (let key in params) {
      if (params[key] !== undefined && params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    return api.formPut({
      formData,
      path: `${API_PREFIX}/return-app/${params.id}`,
    });
  },
  //#endregion return_app

  //#region promotion
  getCoupons: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/coupon` + query,
    });
  },

  getCoupon: async (id) => {
    return api.get({
      path: `${API_PREFIX}/coupon/${id}`,
    });
  },

  addCoupon: async (data) => {
    return api.post({
      path: `${API_PREFIX}/coupon`,
      data,
    });
  },

  editCoupon: async ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/coupon/${id}`,
      data,
    });
  },

  editPromotionFeedback: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `${API_PREFIX}/promotion/feedback`,
      data,
    });
  },

  editPromotionBonus: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `${API_PREFIX}/promotion/bonus`,
      data,
    });
  },

  editPromotionDiscount: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `${API_PREFIX}/promotion/discount`,
      data,
    });
  },

  editPromotionBonusGift: async (data) => {
    return api.post({
      path: `${API_PREFIX}/promotion/bonus-gift`,
      data,
    });
  },

  editPromotionFee: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `${API_PREFIX}/promotion/fee`,
      data,
    });
  },

  addPromotionThreshold: async (data) => {
    return api.post({
      path: `${API_PREFIX}/promotion/threshold`,
      data,
    });
  },

  editPromotionThreshold: async ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/promotion/threshold/${id}`,
      data,
    });
  },

  deletePromotionThreshold: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/promotion/threshold/${id}`,
    });
  },

  // getBonusGiftConfigSetList: async () => {
  //   return api.get({
  //     path: `/api/promotion/bonus-gift/`,
  //   });
  // },

  triggerBonusGift: async (promotionId) => {
    return api.post({
      path: `${API_PREFIX}/promotion/bonus-gift/trigger`,
      data: {
        promotion: promotionId,
      },
    });
  },

  getPromotionDiscount: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/discount${query}`,
    });
  },

  getPromotionFeedback: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/feedback${query}`,
    });
  },

  getPromotionBonus: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/bonus${query}`,
    });
  },

  getPromotionBonusGift: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/bonus-gift${query}`,
    });
  },

  getPromotionFee: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/fee${query}`,
    });
  },

  getPromotionThreshold: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/promotion/threshold${query}`,
    });
  },

  //#endregion promotion

  //#region promo_item
  getPromoItems: async () => {
    return api.get({
      path: `${API_PREFIX}/promo-item`,
    });
  },

  getPromoItem: async (id) => {
    return api.get({
      path: `${API_PREFIX}/promo-item/${id}`,
    });
  },

  // not yet
  editPromoItem: async ({id, imageBase64, ...data}) => {
    if (data.image instanceof File) {
      let formData = new FormData();
      Object.keys(data).forEach((k) => formData.append(k, data[k]));

      return api.formPut({
        path: `${API_PREFIX}/promo-item/${id}`,
        formData,
      });
    } else {
      // if image is not file ,then not bring it
      const {image, ..._data} = data;
      return api.put({
        path: `${API_PREFIX}/promo-item/${id}`,
        data: _data,
      });
    }
  },

  addPromoItem: async ({imageBase64, ...data}) => {
    let formData = new FormData();
    Object.keys(data).forEach((k) => formData.append(k, data[k]));

    return api.formPost({
      path: `${API_PREFIX}/promo-item`,
      formData,
    });
  },

  deletePromoItem: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/promo-item/${id}`,
    });
  },

  //#endregion promo_item

  //#region product
  getProducts: async (params) => {
    let query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/product${query}`,
    });
  },

  getProduct: async (id) => {
    return api.get({
      path: `${API_PREFIX}/product/${id}`,
    });
  },

  addProductDesignSpec: async (data) => {
    return api.post({
      path: `${API_PREFIX}/product/design-spec`,
      data,
    });
  },

  addProduct: async (_params) => {
    let params = {..._params};
    delete params.imageBase64;
    const exclusions = [
      'image',
      'is_on_shelf',
      'file_upload',
      'related_product',
    ];

    for (let field of exclusions) {
      if (!params[field] && params[field] !== false) {
        delete params[field];
      }
    }

    if (params.image instanceof File) {
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      return api.formPost({
        path: `${API_PREFIX}/product`,
        formData,
        secure: true,
      });
    } else {
      return api.post({
        path: `${API_PREFIX}/product`,
        data: params,
      });
    }
  },

  editProduct: async (_params) => {
    let params = {..._params};
    delete params.imageBase64;
    const exclusions = [
      'image',
      'is_on_shelf',
      'file_upload',
      'related_product',
    ];

    for (let field of exclusions) {
      if (!params[field] && params[field] !== false) {
        delete params[field];
      }
    }

    if (params.image instanceof File) {
      let formData = new FormData();

      for (let key in params) {
        formData.append(key, params[key]);
      }

      return api.formPut({
        path: `${API_PREFIX}/product/${_params.id}`,
        formData,
        secure: true,
      });
    } else {
      delete params.image;
      return api.put({
        path: `${API_PREFIX}/product/${params.id}`,
        data: params,
      });
    }
  },

  deleteProduct: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/product/${id}`,
    });
  },

  getProductImages: async (id) => {
    return api.get({
      path: `${API_PREFIX}/product/image?product=${id}`,
    });
  },

  createProductImage: async (data) => {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return api.formPost({
      path: `${API_PREFIX}/product/image`,
      formData,
    });
  },

  createProductDesignSpecPreviewImage: async (data) => {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return api.formPost({
      path: `${API_PREFIX}/product/design-spec/preview-image`,
      formData,
    });
  },

  editProductImage: async (data) => {
    if (data.image) {
      let formData = new FormData();

      for (let key in data) {
        data[key] && formData.append(key, data[key]);
      }

      return api.formPut({
        path: `${API_PREFIX}/product/image/${data.id}`,
        formData,
      });
    } else {
      return api.put({
        path: `${API_PREFIX}/product/image/${data.id}`,
        data,
      });
    }
  },

  editProductDesignSpecPreviewImage: async (data) => {
    if (data.image) {
      let formData = new FormData();

      for (let key in data) {
        data[key] && formData.append(key, data[key]);
      }

      return api.formPut({
        path: `${API_PREFIX}/product/design-spec/preview-image/${data.id}`,
        formData,
      });
    } else {
      return api.put({
        path: `${API_PREFIX}/product/design-spec/preview-image/${data.id}`,
        data,
      });
    }
  },

  editProductDesignSpec: async (data) => {
    return api.put({
      path: `${API_PREFIX}/product/design-spec/${data.id}`,
      data,
    });
  },

  deleteProductImage: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/product/image/${id}`,
    });
  },

  deleteProductDesignSpecPreviewImage: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/product/design-spec/preview-image/${id}`,
    });
  },

  getProductFiles: async (id) => {
    return api.get({
      path: `${API_PREFIX}/product/file?product=${id}`,
    });
  },

  createProductFile: async (data) => {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return api.formPost({
      path: `${API_PREFIX}/product/file`,
      formData,
    });
  },

  editProductFile: async (data) => {
    if (data.file) {
      let formData = new FormData();

      for (let key in data) {
        data[key] && formData.append(key, data[key]);
      }

      return api.formPut({
        path: `${API_PREFIX}/product/file/${data.id}`,
        formData,
      });
    } else {
      return api.put({
        path: `${API_PREFIX}/product/file/${data.id}`,
        data,
      });
    }
  },

  deleteProductFile: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/product/file/${id}`,
    });
  },

  //#endregion product

  //#region member
  getMembers: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/user/profile${query}`,
    });
  },

  getMember: async (id) => {
    return api.get({
      path: `${API_PREFIX}/user/profile/${id}`,
    });
  },

  createUser: async ({username, password, staff_type}) => {
    return api.post({
      path: `${API_PREFIX}/user`,
      secure: true,
      data: {
        username,
        password,
        staff_type,
      },
    });
  },

  editMember: ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/user/profile/${id}`,
      data,
    });
  },

  editCreditBonus: ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/user/profile/${id}/credits`,
      data,
    });
  },

  getRequestUpgrades: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/user/upgrade` + query,
    });
  },

  reviewRequestUpgrade: async ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/user/upgrade/${id}/review`,
      data,
    });
  },

  overdueMonthlyMember: async () => {
    return api.get({
      path: `/api/staff/monthly/remind`,
    });
  },

  getReviewers: async () => {
    return api.get({
      path: `${API_PREFIX}/admin-user?no_page=true`,
    });
  },

  assignReviewer: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order-item-review/assign`,
      data,
    });
  },

  //#endregion member

  //#region address
  getCities: () => {
    return [...new Set(zip3.map((x) => x.city))].map((x) => ({countyName: x}));
    return api.get({
      path: `https://daiwanlang.netlify.app/api/行政區/get`,
      withHost: true,
      secure: false,
    });
  },

  getDistricts: (county) => {
    return zip3
      .filter((x) => x.city === county)
      .map((x) => ({townName: x.district, zipCode: x.zip}));
    return api.get({
      path: `https://daiwanlang.netlify.app/api/行政區/${county}/get`,
      withHost: true,
      secure: false,
    });
  },

  getZipCode: (zipcode) => {
    let result = zip3.find((x) => x.zip === zipcode);
    if (result) {
      return {
        countyName: result.city,
        townName: result.district,
      };
    }

    // return api.get({
    //   path: `https://daiwanlang.netlify.app/api/行政區/郵遞區號/${zipcode}/get`,
    //   withHost: true,
    //   secure: false,
    // })
  },
  //#endregion address

  //#region monthly order
  getMonthlyOrders: (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/order/monthly${query}`,
    });
  },

  getMonthlyOrder: (id) => {
    return api.get({
      path: `${API_PREFIX}/order/monthly/${id}`,
    });
  },

  editMonthlyOrder: ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/order/monthly/${id}`,
      data,
    });
  },

  settleMonthlyOrder: (data) => {
    return api.post({
      path: `${API_PREFIX}/order/monthly`,
      data,
    });
  },

  createMonthlyInvoice: async (data) => {
    return api.post({
      path: `${API_PREFIX}/issue/order/monthly/invoice`,
      data,
    });
  },

  notifyExpiredMonthly: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order/monthly/notify`,
      data,
    });
  },
  //#endregion monthly order

  //#region storage
  getPrivateFile: (key) => {
    return api.post({
      path: `${
        appConfig.endpoint.revStorageHost
      }/storage/read?token=${JwtTokenOutlet.getValue()}`,
      withHost: true,
      secure: false,
      data: {
        filename: key,
      },
    });
  },

  //#endregion storage

  //#region usergroup
  getUserGroups: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/user/group` + query,
    });
  },

  getUserGroup: async (id) => {
    return api.get({
      path: `${API_PREFIX}/user/group/${id}`,
    });
  },

  addUserGroup: async (data) => {
    return api.post({
      path: `${API_PREFIX}/user/group`,
      data,
    });
  },

  editUserGroup: async ({id, ...data}) => {
    return api.put({
      path: `${API_PREFIX}/user/group/${id}`,
      data,
    });
  },
  //#endregion

  //#region period
  getPeriods: async (params) => {
    let query = transToQueryStr(params);
    return api.get({
      path: `${API_PREFIX}/order/period${query}`,
    });
  },

  getPeriod: async (id) => {
    return api.get({
      path: `${API_PREFIX}/order/period/${id}`,
    });
  },

  editPeriod: async (data) => {
    return api.put({
      path: `${API_PREFIX}/order/period/${data.id}`,
      data,
    });
  },

  createPeriodExtra: async (data) => {
    return api.post({
      path: `${API_PREFIX}/order/extra/period`,
      data,
    });
  },

  terminatePeriod: async (data) => {
    return api.post({
      path: `${API_PREFIX}/neweb/period/terminate`,
      data,
    });
  },
  //#endregion period

  rebuild: async () => {
    //return  await api.post(`${appConfig.endpoint.netlifyBuildHook}`, {});
    return api.get({
      path: `${
        appConfig.endpoint.productSpecUploadUrl
      }/misc/rebuild?token=${JwtTokenOutlet.getValue()}`,
      withHost: true,
      secure: false,
    });
  },
  //#region admin user
  fetchMenus: async () => {
    return api.get({
      path: `${API_PREFIX}/menu`,
    });
  },

  checkUserPermission: async (path) => {
    if (path === '/') return;
    try {
      await api.get({
        path: `${API_PREFIX}/menu/check-permission?uri=${path}`,
      });
    } catch (err) {
      if (err.status === 403) {
        navigate(`/404/`);
      } else {
        console.warn(err);
        throw err;
      }
    }
  },
  fetchAdminUser: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/admin-user${query}`,
    });
  },
  addManager: async ({
    email,
    username,
    password,
    password_confirmation,
    name,
    roles,
  }) => {
    return api.post({
      path: `${API_PREFIX}/admin-user`,
      data: {
        email,
        username,
        password,
        password_confirmation,
        permissions: [1],
        name,
        roles,
      },
    });
  },
  fetchManagerDetail: async (id) => {
    return api.get({
      path: `${API_PREFIX}/admin-user/${id}`,
    });
  },
  updateManager: async ({id, name, roles, email}) => {
    return api.put({
      path: `${API_PREFIX}/admin-user/${id}`,
      data: {
        email,
        name,
        roles,
        permissions: [1],
      },
    });
  },
  deleteManagerViaID: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/admin-user/${id}`,
    });
  },
  changeManagerPassword: async (params) => {
    const {id, password, password_confirmation} = params;
    return api.post({
      path: `${API_PREFIX}/admin-user/${id}/change-password`,
      data: {
        password,
        password_confirmation,
      },
    });
  },
  fetchRoleList: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/role${query}`,
    });
  },
  fetchRoleDetail: async (id) => {
    return api.get({
      path: `${API_PREFIX}/role/${id}`,
    });
  },
  addRole: async ({name, slug, comment, menus, is_amount_visible}) => {
    return api.post({
      path: `${API_PREFIX}/role`,
      data: {
        name,
        slug,
        comment,
        permissions: [1],
        menus,
        is_amount_visible,
      },
    });
  },
  deleteRoleViaID: async (id) => {
    return api.delete({
      path: `${API_PREFIX}/role/${id}`,
    });
  },
  updateRole: async ({id, name, comment, menus, is_amount_visible}) => {
    return api.put({
      path: `${API_PREFIX}/role/${id}`,
      data: {
        name,
        comment,
        menus,
        permissions: [1],
        is_amount_visible,
      },
    });
  },
  //#endregion admin user

  //#region logistic center
  dispatchLogisticByID: async (id) => {
    return api.post({
      path: `${API_PREFIX}/logistics/${id}/dispatch`,
    });
  },
  printLogisticByID: async (id) => {
    return api.post({
      path: `${API_PREFIX}/logistics/${id}/print`,
    });
  },
  duplicateLogisticByID: async ({id, quantity}) => {
    return api.post({
      path: `${API_PREFIX}/logistics/${id}/duplicate`,
      data: {
        quantity,
      },
    });
  },
  cancelLogisticByID: async (id) => {
    return api.post({
      path: `${API_PREFIX}/logistics/${id}/cancel`,
    });
  },
  batchDispatchLogistic: async (data) => {
    return api.post({
      path: `${API_PREFIX}/logistics/batch/dispatch`,
      data,
    });
  },
  batchPrintLogistic: async (data) => {
    return api.post({
      path: `${API_PREFIX}/logistics/batch/print`,
      data,
    });
  },
  fetchLogisticFiles: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${API_PREFIX}/batch/file` + query,
    });
  },
  getLogisticsSenders: async () => {
    return api.get({
      path: `${API_PREFIX}/logistics/senders`,
    });
  },
  getPayuniShipMap: () =>
    `${appConfig.endpoint.apiUrl}/api/v1/logistics/payuni/ship-map`,
  getXDeliveryShipMap: (redirect_url) =>
    `${appConfig.endpoint.apiUrl}/api/v1/logistics/xdelivery/ship-map?redirect_url=${redirect_url}`,

  //#endregion logistic center
};
ActionOutlet.update(Actions);
