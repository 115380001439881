import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import BackButton from '../../Components/BackButton';
import Block from '../../Widgets/Block';
import Hint from '../../Widgets/Hint';
import {
  Input,
  Select,
  Checkbox,
  Table,
  message,
  Row as AntRow,
  Col as AntCol,
  Switch,
  Divider,
} from 'antd';
import {PAYMENT_TYPE} from '../../Utils/OrderUtil';
import getOptions from '../../Utils/getOptions';
import MemberSelectDialog from '../../Dialogs/MemberSelectDialog';
import CustomItemDialog, {UISTATE as DialogUiState} from './CustomItemDialog';
import AddressField from '../../Components/AddressField';
import InvoiceConfig, {validateInvoice} from '../../Components/InvoiceConfig';
import {useOutlet} from 'reconnect.js';
import validate from '../../Utils/validate';
import {errorHandler as _errorHandler, ErrCustomOrder} from '../../errors';
import {navigate} from 'gatsby';
import OrderCalculation from '../../Components/OrderCalculation';
import RoleValidator from '../../Domain/RoleValidator';
import Row from '../../Widgets/Row';
import Button from '../../Widgets/Button';
import {EditBox} from '@styled-icons/remix-line/EditBox';
import {CloseCircle} from '@styled-icons/remix-line/CloseCircle';
// import Edit from "@styled-icons/material-outlined/Edit";
import IconButton from '../../Widgets/IconButton';
import {MEMBER_TYPE, DELIVERY_TYPE} from '../../dictionary';

const appConfig = require('../../data.json');
const {isNotEmpty, isMobileNumber, isEmail} = validate;

function errorHandler(err) {
  if (Array.isArray(err.coupon)) {
    if (/Object with code=(\w+) does not exist\./.test(err.coupon[0])) {
      return message.error('此促銷碼不存在');
    }
  } else if (err.error) {
    return message.error(
      {
        order_coupon_threshold_error: '未到此促銷碼可使用門檻',
        coupon_expired: '促銷碼已過期',
        coupon_usage_limit: '促銷碼已到使用上限',
        coupon_user_group_error: '用戶不在此優惠群組',
        coupon_user_usage_limit: '促銷碼已達此會員使用上限',
      }[err.error] || '自定義訂單發生錯誤',
    );
  } else {
    _errorHandler(err);
  }
}

const payment_options = getOptions(PAYMENT_TYPE).filter((x) =>
  [appConfig.paymentProvider, 'offline', 'credits', 'monthly'].includes(
    x.value,
  ),
);
const logistic_options = getOptions(DELIVERY_TYPE).filter(
  (x) =>
    x.value !== 'all' &&
    x.value !== 'ezship' &&
    appConfig.supportLogistics.includes(x.value),
);

function ItemBlock({items, setItems}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [index, setIndex] = useState(-1);
  return (
    <Block>
      <div style={{display: 'flex', justifyContent: 'space-around'}}>
        <h2>商品資訊</h2>
        <div style={{flex: 1}} />
        <Button
          type="primary"
          onClick={() => {
            setIndex(-1);
            setOpenDialog(true);
          }}>
          新增商品
        </Button>
      </div>
      <div className="divider" />
      <Table
        dataSource={items}
        columns={[
          {title: '名稱', key: 'item_name', dataIndex: 'item_name'},
          {title: '規格', key: 'custom_note', dataIndex: 'custom_note'},
          {title: '數量', key: 'item_quantity', dataIndex: 'item_quantity'},
          {title: '價格', key: 'item_amount', dataIndex: 'item_amount'},
          {title: '成本', key: 'item_cost', dataIndex: 'item_cost'},
          {title: '運費', key: 'logistic_fee', dataIndex: 'logistic_fee'},
          {
            title: '編輯',
            key: 'action',
            render: (value, item, index) => (
              <div style={{display: 'flex'}}>
                <IconButton
                  onClick={() => {
                    setIndex(index);
                    setOpenDialog(true);
                  }}
                  hoverColor={appConfig.colors.sub}>
                  <EditBox />
                </IconButton>
                <IconButton
                  hoverColor={appConfig.colors.error}
                  style={{marginLeft: 10}}
                  onClick={() => {
                    setItems((prev) => {
                      let _items = [...prev];
                      _items.splice(index, 1);
                      return _items;
                    });
                  }}>
                  <CloseCircle />
                </IconButton>
              </div>
            ),
          },
        ]}
        pagination={false}
        rowKey="item_name"
      />
      <CustomItemDialog
        item={items[index]}
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        onChange={(uiState, item) => {
          if (uiState === DialogUiState.CREATE) {
            setItems((prev) => [...prev, item]);
          } else {
            setItems((prev) => {
              let _items = [...prev];
              _items[index] = item;
              return _items;
            });
          }
        }}
      />
    </Block>
  );
}

function LogisticBlock({config, setConfig, values, setValues}) {
  return (
    <Block>
      <div className="title">
        <h2>物流方式</h2>
      </div>
      <div className="divider" />
      <div>
        <div className="row">
          <h4>物流方式</h4>
          <Select
            value={config.delivery_type}
            onChange={(value) => {
              setConfig({delivery_type: value, is_delivery_private: false});

              // if (value === 'self_pick') {
              //   setValues({logistic_fee: 0});
              // }
              // if (value === 'ezship') {
              //   setValues({logistic_fee: 80});
              // }
            }}
            style={{width: 200}}>
            {logistic_options.map((option, idx) => (
              <Select.Option key={idx} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        {['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
          config.delivery_type,
        ) && (
          <div className="row">
            <h4>保密代寄</h4>
            <Checkbox
              checked={config.is_delivery_private}
              onChange={(e) =>
                setConfig({is_delivery_private: e.target.checked})
              }
              style={{marginRight: 5}}>
              保密代寄
            </Checkbox>
            <Hint type="info">
              可自定義寄件資訊，以自訂寄件人名義將商品寄送給收件人。
            </Hint>
          </div>
        )}

        {['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
          config.delivery_type,
        ) && (
          <React.Fragment>
            <div className="row">
              <h4>收件人姓名</h4>
              <Input
                value={config.receiver_name}
                onChange={(e) => setConfig({receiver_name: e.target.value})}
                style={{marginRight: 5}}
              />
              <Button
                type="primary"
                size="small"
                color={appConfig.colors.sub}
                onClick={() => {
                  setConfig({
                    receiver_name: values.config.name,
                    zip: values.config.zip_code,
                    receiver_city: values.config.city,
                    receiver_district: values.config.district,
                    receiver_address: values.config.address,
                    receiver_phone: values.config.phone,
                    receiver_tel: values.config.tel,
                  });
                }}>
                同會員資料
              </Button>
            </div>

            <div className="row">
              <h4>收件地址</h4>
              <AddressField
                zip_code={config.zip}
                city={config.receiver_city}
                district={config.receiver_district}
                address={config.receiver_address}
                onChange={({zip_code, city, district, address}) =>
                  setConfig({
                    ...(zip_code !== undefined && {zip: zip_code}),
                    ...(city && {receiver_city: city}),
                    ...(district && {receiver_district: district}),
                    ...(address !== undefined && {
                      receiver_address: address,
                    }),
                  })
                }
              />
            </div>

            <div className="row">
              <h4>收件人手機</h4>
              <Input
                value={config.receiver_phone}
                onChange={(e) => setConfig({receiver_phone: e.target.value})}
              />
            </div>

            <div className="row">
              <h4>收件人市話</h4>
              <Input
                value={config.receiver_tel}
                onChange={(e) => setConfig({receiver_tel: e.target.value})}
              />
            </div>
          </React.Fragment>
        )}

        {config.is_delivery_private && (
          <React.Fragment>
            <Divider />
            <div className="row">
              <h4>寄件人姓名</h4>
              <Input
                value={config.sender_name}
                onChange={(e) => setConfig({sender_name: e.target.value})}
                style={{marginRight: 5}}
              />
              <Button
                type="primary"
                size="small"
                color={appConfig.colors.sub}
                onClick={() => {
                  setConfig({
                    sender_name: values.config.name,
                    sender_zip: values.config.zip_code,
                    sender_city: values.config.city,
                    sender_district: values.config.district,
                    sender_address: values.config.address,
                    sender_phone: values.config.phone,
                    sender_tel: values.config.tel,
                  });
                }}>
                同會員資料
              </Button>
            </div>

            <div className="row">
              <h4>寄件人地址</h4>
              <AddressField
                zip_code={config.sender_zip}
                city={config.sender_city}
                district={config.sender_district}
                address={config.sender_address}
                onChange={({zip_code, city, district, address}) =>
                  setConfig({
                    ...(zip_code !== undefined && {sender_zip: zip_code}),
                    ...(city && {sender_city: city}),
                    ...(district && {sender_district: district}),
                    ...(address !== undefined && {
                      sender_address: address,
                    }),
                  })
                }
              />
            </div>

            <div className="row">
              <h4>寄件人手機</h4>
              <Input
                value={config.sender_phone}
                onChange={(e) => setConfig({sender_phone: e.target.value})}
              />
            </div>

            <div className="row">
              <h4>寄件人市話</h4>
              <Input
                value={config.sender_tel}
                onChange={(e) => setConfig({sender_tel: e.target.value})}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </Block>
  );
}

export default function CustomOrderFormPage(props) {
  const [values, _setValues] = useState({
    note: '',
    payment_type: appConfig.paymentProvider,
    ignore_invoice: false,
    coupon: '',
    config: {
      name: '',
      email: '',
      phone: '',
      zip_code: '',
      address: '',
      city: '',
      district: '',
      tel: '',
      use_bonus: false,
      invoiceConfig: {
        invoice_type: 'two_copies',
        invoice_subtype: 'ezpay_vehicle',
        mobile_vehicle_code: '',
        citizen_personal_certificate_code: '',
        company_title: '',
        gui_number: '',
        donate_foundation_gui: '',
        love_code: '',
      },
      deliveryConfig: {
        delivery_type: 'self_pick',
        is_delivery_private: false,
        zip: '',
        receiver_city: '',
        receiver_district: '',
        receiver_address: '',
        receiver_name: '',
        receiver_phone: '',
        receiver_tel: '',
        sender_zip: '',
        sender_city: '',
        sender_district: '',
        sender_address: '',
        sender_name: '',
        sender_phone: '',
        sender_tel: '',
      },
    },
  });
  const [actions] = useOutlet('actions');
  const [items, setItems] = useState([]);
  const [buyer, setBuyer] = useState();
  const [calculations, setCalculations] = useState();
  const [openMemberSelectDialog, setOpenMemberSelectDialog] = useState(false);

  const setValues = useCallback((obj) => {
    _setValues((prev) => ({...prev, ...obj}));
  }, []);

  const setConfig = useCallback((obj) => {
    _setValues((prev) => ({...prev, config: {...prev.config, ...obj}}));
  }, []);

  const valid = useCallback((values, items, buyer) => {
    const {config} = values;
    const {
      name,
      phone,
      email,
      city,
      district,
      address,
      deliveryConfig,
      invoiceConfig,
    } = config;
    const {
      delivery_type,
      is_delivery_private = '',
      receiver_name = '',
      receiver_phone = '',
      receiver_address = '',
      receiver_tel = '',
      sender_name = '',
      sender_phone = '',
      sender_address = '',
    } = deliveryConfig;

    if (!buyer) {
      throw new ErrCustomOrder('顧客資訊請先從搜尋結果選擇');
    }

    // user config
    if (!isNotEmpty(name) || !isNotEmpty(email) || !isNotEmpty(phone)) {
      throw new ErrCustomOrder('請填入會員資料必填欄位');
    }
    if (!isEmail(email)) {
      throw new ErrCustomOrder('會員資料電子信箱格式錯誤');
    }
    if (!isMobileNumber(phone)) {
      throw new ErrCustomOrder('會員資料手機格式錯誤');
    }
    if (!isNotEmpty(city) || !isNotEmpty(district) || !isNotEmpty(address)) {
      throw new ErrCustomOrder('請填入會員聯絡地址');
    }

    if (!isNotEmpty(values.note)) {
      throw new ErrCustomOrder('請填入備註');
    }

    if (items.length <= 0) {
      throw new ErrCustomOrder('必須新增一項商品');
    }

    for (let item of items) {
      if (item.item_name.length > 30) {
        throw new ErrCustomOrder('商品名稱超過30字會導致開立發票失敗');
      }
      if (!isNotEmpty(item.custom_note)) {
        throw new ErrCustomOrder(`商品規格需填寫`);
      }
      if (!item.item_quantity) {
        throw new ErrCustomOrder(`商品數量需填寫`);
      }
      if (isNaN(item.item_amount)) {
        throw new ErrCustomOrder(`商品價錢需填寫`);
      }
      if (item.logistic_fee > 0 && delivery_type === 'self_pick') {
        throw new ErrCustomOrder('自取不可有運費');
      }
    }

    //delivery config
    if (
      ['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
        delivery_type,
      )
    ) {
      if (
        !isNotEmpty(receiver_name) ||
        !isNotEmpty(receiver_phone) ||
        !isNotEmpty(receiver_address) ||
        !isNotEmpty(receiver_tel)
      ) {
        throw new ErrCustomOrder('請填入宅配運送/收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCustomOrder('收件人手機格式錯誤');
      }
    } else {
      // self pick
    }

    if (is_delivery_private) {
      /* 保密代記 */
      if (
        !isNotEmpty(sender_name) ||
        !isNotEmpty(sender_phone) ||
        !isNotEmpty(sender_address)
      ) {
        throw new ErrCustomOrder('請填入保密代寄/寄件人必填欄位');
      }
      if (!isMobileNumber(sender_phone)) {
        throw new ErrCustomOrder('保密代寄/寄件人手機格式錯誤');
      }

      if (
        sender_name === receiver_name ||
        sender_phone === receiver_phone ||
        sender_address === receiver_address
      ) {
        throw new ErrCustomOrder('保密代寄/寄件人資料不可和收件人資料相同');
      }
    }

    // invoice config
    validateInvoice(invoiceConfig);
  }, []);

  const calculate = useCallback(async (values, items, buyer, onError) => {
    setCalculations();
    actions.setLoading(true);
    try {
      valid(values, items, buyer);
      // if (!buyer) {
      //   throw new ErrCustomOrder('請選擇顧客');
      // }
      // if (items.length === 0) {
      //   throw new ErrCustomOrder('請新增一項商品');
      // }
      // if (!isNotEmpty(values.note)) {
      //   throw new ErrCustomOrder('請填入備註');
      // }

      let resp = await actions.calcCustomOrder({
        note: values.note,
        buyer: buyer.id,
        payment_type: values.payment_type,
        ignore_invoice: values.ignore_invoice,
        coupon: values.coupon || undefined,
        items,
        config: values.config,
      });

      setCalculations(resp);
    } catch (err) {
      errorHandler(err);
      onError();
    }
    actions.setLoading(false);
  }, []);

  const submit = useCallback(async (values, items, buyer) => {
    actions.setLoading(true);
    try {
      // TODO: valid
      valid(values, items, buyer);

      await actions.createCustomOrder({
        note: values.note,
        buyer: buyer.id,
        config: values.config,
        payment_type: values.payment_type,
        ignore_invoice: values.ignore_invoice,
        coupon: values.coupon || undefined,
        items,
      });

      message.success('創建訂單成功！');
      navigate('/orders/');
    } catch (ex) {
      errorHandler(ex);
    }
    actions.setLoading(false);
  }, []);

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton pathname="/orders/" />
      </Row>
      <AntRow gutter={[20, 20]} style={{marginBottom: 20}}>
        <AntCol span={9}>
          <Block>
            <h2>建立自定義訂單</h2>
            <div className="divider" />
            <div>
              <div className="row">
                <h4>付款方式</h4>
                <Select
                  value={values.payment_type}
                  onChange={(value) => setValues({payment_type: value})}>
                  {payment_options.map((option, idx) => (
                    <Select.Option
                      key={idx}
                      value={option.value}
                      disabled={
                        buyer?.user_type === 'monthly'
                          ? option.value !== 'monthly'
                          : option.value === 'monthly'
                      }>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="row">
                <h4>使用紅利折抵</h4>
                <Switch
                  checked={values.config.use_bonus}
                  onChange={(value) => {
                    setConfig({use_bonus: value});
                    if (value) {
                      calculate(
                        {
                          ...values,
                          config: {
                            ...values.config,
                            use_bonus: true,
                          },
                        },
                        items,
                        buyer,
                        () => {
                          setConfig({use_bonus: false});
                        },
                      );
                    }
                  }}
                />
                {calculations && (
                  <div style={{marginLeft: 10}}>
                    可折抵紅利 {calculations.bonus + calculations.bonus_tax}
                  </div>
                )}
              </div>
              <div className="row">
                <h4>折扣碼</h4>
                <Input.Search
                  value={values.coupon}
                  onChange={(e) => setValues({coupon: e.target.value})}
                  onSearch={(value) => {
                    if (value) {
                      calculate(
                        {
                          ...values,
                          coupon: value,
                        },
                        items,
                        buyer,
                        () => {
                          setValues({coupon: ''});
                        },
                      );
                    }
                  }}
                />
              </div>
              <div className="row">
                <h4>備註</h4>
                <Input.TextArea
                  value={values.note}
                  onChange={(e) => setValues({note: e.target.value})}
                />
              </div>
              <Hint type="warning">此為客人看得到的備註</Hint>
            </div>
          </Block>
        </AntCol>
        <AntCol span={6}>
          <Block>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <h2>訂單金額</h2>
              <Button onClick={() => calculate(values, items, buyer, () => {})}>
                試算金額
              </Button>
            </div>
            <div className="divider" />

            <div>
              <OrderCalculation calculations={calculations} />
            </div>
          </Block>
        </AntCol>
        <AntCol span={9}>
          <Block>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <h2>顧客資訊</h2>
                <div className="subtitle">
                  請先由搜尋結果選擇，訂單需對應存在的顧客資訊
                </div>
              </div>
              <div style={{flex: 1}} />

              <Button
                size="small"
                type="primary"
                onClick={() => setOpenMemberSelectDialog(true)}>
                選擇會員
              </Button>
            </div>
            <div className="divider" />
            <div>
              <div className="row">
                <h4>會員</h4>
                <div>
                  編號 # {buyer?.id || '尚未選擇'} {buyer?.name}
                </div>
              </div>

              <div className="row">
                <h4>姓名</h4>
                <Input
                  value={values.config.name}
                  onChange={(e) => setConfig({name: e.target.value})}
                />
              </div>
              <div className="row">
                <h4>會員身份</h4>
                <div>{MEMBER_TYPE[buyer?.user_type] || '-'}</div>
              </div>

              <div className="row">
                <h4>公司抬頭</h4>
                <div>{buyer?.ent_name}</div>
              </div>

              <div className="row">
                <h4>統一編號</h4>
                <div>{buyer?.gui_number}</div>
              </div>

              <div className="row">
                <h4>行動電話</h4>
                <Input
                  value={values.config.phone}
                  onChange={(e) => setConfig({phone: e.target.value})}
                />
              </div>

              <div className="row">
                <h4>電子信箱</h4>
                <Input
                  value={values.config.email}
                  onChange={(e) => setConfig({email: e.target.value})}
                />
              </div>

              <div className="row">
                <h4>聯絡地址</h4>
                <AddressField
                  zip_code={values.config.zip_code}
                  city={values.config.city}
                  district={values.config.district}
                  address={values.config.address}
                  onChange={({zip_code, city, district, address}) =>
                    setConfig({
                      ...(zip_code !== undefined && {zip_code}),
                      ...(city && {city}),
                      ...(district && {district}),
                      ...(address !== undefined && {address}),
                    })
                  }
                />
              </div>

              {/* <div className="row">
              <h4>電話</h4>
              <div>{values.config.tel}</div>
            </div> */}
            </div>
          </Block>
        </AntCol>

        <AntCol span={12}>
          <LogisticBlock
            config={values.config.deliveryConfig}
            setConfig={(value) =>
              setConfig({
                deliveryConfig: {...values.config.deliveryConfig, ...value},
              })
            }
            values={values}
            setValues={setValues}
          />
        </AntCol>
        <AntCol span={12}>
          <Block>
            <h2>發票方式</h2>
            <div className="divider" />
            <div>
              <div className="row">
                <h4>不開立發票</h4>

                <Switch
                  checked={values.ignore_invoice}
                  onChange={(value) =>
                    setValues({ignore_invoice: value})
                  }></Switch>
              </div>

              {!values.ignore_invoice && (
                <InvoiceConfig
                  config={values.config.invoiceConfig}
                  setConfig={(invoiceConfig) => setConfig({invoiceConfig})}
                />
              )}
            </div>
          </Block>
        </AntCol>
        <AntCol span={24}>
          <ItemBlock items={items} setItems={setItems} />
        </AntCol>
      </AntRow>

      <div className="row">
        <div style={{flex: 1}} />
        <Button
          type="primary"
          color="#3CC2A1"
          onClick={() => submit(values, items, buyer)}>
          送出
        </Button>
      </div>

      <MemberSelectDialog
        visible={openMemberSelectDialog}
        onClose={() => setOpenMemberSelectDialog(false)}
        selectedId={buyer?.id}
        onChange={(member) => {
          setBuyer(member);
          setConfig({
            name: member.name,
            phone: member.phone,
            email: member.email,
            zip_code: member.zip_code,
            address: member.address,
            city: member.city,
            district: member.district,
            tel: member.tel,
            tel_ext: member.tel_ext,
            use_bonus: false,
          });

          setValues({
            payment_type:
              member.user_type === 'monthly'
                ? 'monthly'
                : appConfig.paymentProvider,
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding: 20px;

  & > .row {
    display: flex;
    flex-direction: 'row';
  }

  & > .col {
    display: flex;
    flex-direction: 'column';
  }

  & p {
    font-size: 13px;
    letter-spacing: 1.3px;
  }
`;
