import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as Ant from 'antd';
import OrderTable, {TableTypes} from '../../Components/OrderTable';
import OrderStateFilter from '../../Components/OrderStateFilter';
import {navigate} from 'gatsby';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import Tabs from '../../Widgets/Tabs';
import {PAYMENT_STATUS, ORDER_TYPE, ORDER_STATE} from '../../dictionary';

const appConfig = require('../../data.json');
const qs = require('query-string');

export const UI_STATE = {
  LIST: 'LIST',
  REVIEW: 'REVIEW',
  PRODUCTION: 'PRODUCTION',
};

function getPaymentTypeOptions(provider) {
  const baseOptions = [
    {label: '全部', value: 'all'},
    {label: '點數付款', value: 'credits'},
    {label: '臨櫃匯款', value: 'offline'},
    {label: '臨櫃匯款（待對帳）', value: 'offline-remitted'},
  ];

  const providerSpecificOptions = {
    neweb: [
      {label: '藍新-信用卡', value: 'neweb-credit'},
      {label: '藍新-ATM', value: 'neweb-atm'},
      {label: '藍新-超商', value: 'neweb-cvs'},
    ],
    payuni: [
      {label: '統一-信用卡', value: 'payuni-credit'},
      {label: '統一-Line Pay', value: 'payuni-line_pay'},
      {label: '統一-ATM', value: 'payuni-atm'},
      {label: '統一-超商', value: 'payuni-cvs'},
    ],
  };

  return [
    baseOptions[0],
    ...(providerSpecificOptions[provider] || []),
    ...baseOptions.slice(1),
  ];
}

const SelectOptions = {
  Sort: [
    {label: '時間由新到舊', value: '-created'},
    {label: '價格由高到低', value: '-amount'},
  ],
  buyer_type: [
    {value: '', label: '全部'},
    {value: 'normal', label: '一般會員'},
    {value: 'vip', label: '預繳會員'},
    {value: 'enterprise', label: '一般企業'},
    {value: 'ent_vip', label: '企業預繳會員'},
    {value: 'monthly', label: '月結企業'},
    // {value: 'ent_vip_monthly', label: '企業月結預繳會員'},
  ],
  paymentStatus: Object.keys(PAYMENT_STATUS)
    .filter((key) => !['processing', 'code_generated'].includes(key))
    .map((key) => ({
      value: key,
      label: PAYMENT_STATUS[key],
    })),
  paymentType: getPaymentTypeOptions(appConfig.paymentProvider),
  order_type: [{label: '全部', value: ''}].concat(
    Object.keys(ORDER_TYPE)
      .filter((key) => (['period'].includes(key) ? appConfig.alpha : true))
      .map((key) => ({
        label: ORDER_TYPE[key],
        value: key,
      })),
  ),
};

const ALL_STATUS = SelectOptions.paymentStatus[0].value;

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e =
          e +
          `${idx === 0 ? '?' : '&'}${key}=${encodeURIComponent(params[key])}`;
        return e;
      }, '');
  }
  return query;
}

export default function OrderListPage(props) {
  const {location, pageContext} = props;
  const {uiState} = pageContext;
  const filters = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      ordering:
        query.ordering ||
        pageContext.filters?.ordering ||
        SelectOptions.Sort[0].value,
      buyer_type: query.buyer_type || SelectOptions.buyer_type[0].value,
      order_type: query.order_type || SelectOptions.order_type[0].value,
      payment_status: query.payment_status || ALL_STATUS,
      payment_type: query.payment_type || SelectOptions.paymentType[0].value,
      search: query.search || '',
      from: query.from || null,
      to: query.to || null,
      payment_condition: query.payment_condition || '',
      is_item_accepted: query.is_item_accepted === 'true' ? true : false,
      is_item_manufacture: query.is_item_manufacture === 'true' ? true : false,
      display_state:
        query.display_state || pageContext.filters?.display_state || '',
      my_assignment: query.my_assignment === 'true' ? true : false,
      is_urgent: query.is_urgent === 'true' ? true : false,
      is_proof: query.is_proof === 'true' ? true : false,
      gui_number: query.gui_number || '',
      ent_name: query.ent_name || '',
      pageSize: parseInt(query.pageSize) || PAGINATION_INIT.pageSize,
      current: parseInt(query.current) || PAGINATION_INIT.current,
      is_share: query.is_share === 'true' ? true : false,
      is_designed: query.is_designed === 'true' ? true : false,
    };
  }, [location.search, pageContext.filters]);

  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [actions] = useOutlet('actions');

  // const filtersChange = useCallback(obj => setFilters(prev => ({ ...prev, ...obj })), []);

  const filtersChange = useCallback(
    (obj) => {
      const query = transToQueryStr({
        ...filters,
        ...obj,
      });
      navigate(`${location.pathname}${query}`);
    },
    [filters],
  );

  const getOrders = useCallback(async () => {
    actions.setLoading(true);
    let params = {
      ...filters,
      order_type: filters.order_type,
      payment_status:
        filters.payment_status === 'all' ? null : filters.payment_status,
      payment_type:
        filters.payment_type === 'all' ? null : filters.payment_type,
      offset: (filters.current - 1) * filters.pageSize,
      limit: filters.pageSize,
    };

    if (params.payment_status === 'waiting') {
      params.payment_status = 'waiting,processing,code_generated';
    }

    if (['logistic_tallying'].includes(params.display_state)) {
      params.display_state = 'logistic_tallying,logistic_transit';
    }

    if (
      params.payment_type &&
      params.payment_type.indexOf(`${appConfig.paymentProvider}-`) > -1
    ) {
      let [_payment_type, _payment_subtype] = params.payment_type.split('-');
      params.payment_type = _payment_type;
      params.payment_subtype = _payment_subtype;
    }

    if (params.payment_type === 'offline-remitted') {
      params.is_remitted = true;
      params.payment_type = 'offline';
      params.payment_status = 'waiting,processing,code_generated';
    }

    try {
      const resp = await actions.getOrders(params);
      setOrders(resp.results);
      setTotal(resp.count);
    } catch (ex) {
      errorHandler(ex, '取得訂單列表錯誤');
    }
    actions.setLoading(false);
  }, [actions, filters]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  if (uiState === UI_STATE.LIST) {
    return (
      <Wrapper>
        <Block style={{marginBottom: 20}}>
          <h2>訂單篩選</h2>
          <div className="divider" />
          <div>
            <div className="row">
              <h4 className="short">會員類型</h4>
              <Ant.Select
                value={filters.buyer_type}
                onChange={(nextValue) => {
                  filtersChange({buyer_type: nextValue, ...PAGINATION_INIT});
                }}
                style={{marginRight: 40}}>
                {SelectOptions.buyer_type
                  .filter(
                    (x) =>
                      appConfig.alpha || !['vip', 'ent_vip'].includes(x.value),
                  )
                  .map((option, idx) => (
                    <Ant.Select.Option key={idx} value={option.value}>
                      {option.label}
                    </Ant.Select.Option>
                  ))}
              </Ant.Select>

              <h4 className="short">訂單類型</h4>
              <Ant.Select
                value={filters.order_type}
                onChange={(nextValue) => {
                  filtersChange({order_type: nextValue, ...PAGINATION_INIT});
                }}>
                {SelectOptions.order_type.map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            </div>

            <div className="row">
              <h4 className="short">付款方式</h4>
              <Ant.Select
                value={filters.payment_type}
                onChange={(nextValue) => {
                  filtersChange({payment_type: nextValue, ...PAGINATION_INIT});
                }}
                style={{marginRight: 40}}>
                {SelectOptions.paymentType.map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>

              <h4 className="short">付款狀態</h4>
              <Ant.Select
                value={filters.payment_status}
                onChange={(nextValue) => {
                  filtersChange({
                    payment_status: nextValue,
                    ...PAGINATION_INIT,
                  });
                }}>
                {SelectOptions.paymentStatus.map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            </div>

            <div className="row">
              <h4 className="short">新舊排序</h4>

              <Ant.Select
                value={filters.ordering}
                onChange={(nextValue) => {
                  filtersChange({ordering: nextValue, ...PAGINATION_INIT});
                }}
                style={{marginRight: 40}}>
                {SelectOptions.Sort.map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>

              <h4 className="short">訂單時間</h4>
              <Ant.DatePicker.RangePicker
                ranges={{
                  本日: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={(dates, dateStrings) => {
                  filtersChange({
                    from: dateStrings[0],
                    to: dateStrings[1],
                    ...PAGINATION_INIT,
                  });
                }}
                placeholder={['開始日期', '結束日期']}
              />
            </div>

            <div className="row">
              <h4 className="short">搜尋</h4>
              <Ant.Input.Search
                defaultValue={filters.search}
                placeholder="訂單編號或購買者"
                onSearch={(str) =>
                  filtersChange({search: str, ...PAGINATION_INIT})
                }
                allowClear
                style={{marginRight: 40}}
              />
              <h4 className="short">急件商品</h4>
              <Ant.Checkbox
                checked={filters.is_urgent}
                onChange={(e) =>
                  filtersChange({
                    is_urgent: e.target.checked,
                    ...PAGINATION_INIT,
                  })
                }
                style={{marginRight: 40}}
              />
              <h4 className="short">打樣商品</h4>
              <Ant.Checkbox
                checked={filters.is_proof}
                onChange={(e) =>
                  filtersChange({
                    is_proof: e.target.checked,
                    ...PAGINATION_INIT,
                  })
                }
                style={{marginRight: 40}}
              />
              {appConfig.paymentProvider === 'payuni' && (
                <>
                  <h4 className="short">授權分享</h4>
                  <Ant.Checkbox
                    checked={filters.is_share}
                    onChange={(e) =>
                      filtersChange({
                        is_share: e.target.checked,
                        ...PAGINATION_INIT,
                      })
                    }
                    style={{marginRight: 40}}
                  />
                  <h4 className="short">編輯器</h4>
                  <Ant.Checkbox
                    checked={filters.is_designed}
                    onChange={(e) =>
                      filtersChange({
                        is_designed: e.target.checked,
                        ...PAGINATION_INIT,
                      })
                    }
                  />
                </>
              )}
            </div>

            <div className="row">
              <h4 className="short">統編</h4>
              <Ant.Input.Search
                defaultValue={filters.gui_number}
                placeholder="統編"
                onSearch={(str) =>
                  filtersChange({gui_number: str, ...PAGINATION_INIT})
                }
                allowClear
                style={{marginRight: 40}}
              />

              <h4 className="short">公司抬頭</h4>
              <Ant.Input.Search
                defaultValue={filters.ent_name}
                placeholder="抬頭"
                onSearch={(str) =>
                  filtersChange({ent_name: str, ...PAGINATION_INIT})
                }
                allowClear
              />
            </div>

            <div className="row">
              <h4 className="short">訂單狀態</h4>
              <OrderStateFilter
                onChange={(value) =>
                  filtersChange({display_state: value, ...PAGINATION_INIT})
                }
                value={filters.display_state}
              />
            </div>
          </div>
          <div className="divider" />

          <div className="row" style={{margin: 0}}>
            <div style={{fontWeight: '500', marginRight: 5}}>共</div>
            <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
            <div style={{fontWeight: '500', marginRight: 5}}>筆</div>

            <div style={{flex: 1}} />
            <Button
              onClick={() => navigate('/order/custom/')}
              style={{marginRight: 10}}>
              自定義訂單
            </Button>
            <Button
              type="primary"
              onClick={getOrders}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </Block>
        <OrderTable
          orders={orders}
          mounted={true}
          onUpdate={getOrders}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onPaging={(pagination) =>
            filtersChange({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      </Wrapper>
    );
  } else if (uiState === UI_STATE.REVIEW) {
    return (
      <Wrapper>
        <Block style={{marginBottom: 20}}>
          <div>
            <div className="row">
              <h4 className="short">搜尋</h4>
              <Ant.Input.Search
                placeholder="訂單編號或購買者"
                onSearch={(str) => filtersChange({search: str})}
                allowClear
                style={{marginRight: 40}}
              />
              <h4 className="short">排序方式</h4>
              <Ant.Select
                value={filters.ordering}
                onChange={(nextValue) => {
                  filtersChange({ordering: nextValue, ...PAGINATION_INIT});
                }}>
                {[
                  {label: '稿件上傳時間', value: 'last_upload_time'},
                  {label: '更新時間 新到舊', value: '-updated'},
                  {label: '更新時間 舊到新', value: '+updated'},
                  {label: '急件時間', value: 'urgent_time'},
                ].map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            </div>

            <div className="row">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否為急件商品
                </h4>
                <Ant.Checkbox
                  checked={filters.is_urgent}
                  onChange={(e) =>
                    filtersChange({
                      is_urgent: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否為打樣商品
                </h4>
                <Ant.Checkbox
                  checked={filters.is_proof}
                  onChange={(e) =>
                    filtersChange({
                      is_proof: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否使用編輯器
                </h4>
                <Ant.Checkbox
                  checked={filters.is_designed}
                  onChange={(e) =>
                    filtersChange({
                      is_designed: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="divider" />

          <div className="row" style={{margin: 0}}>
            <div style={{fontWeight: '500', marginRight: 5}}>共</div>
            <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
            <div style={{fontWeight: '500', marginRight: 5}}>筆</div>

            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getOrders}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </Block>
        <Block>
          <div>
            <Tabs
              value={filters.my_assignment}
              options={[
                {label: '全部訂單', value: false},
                {label: '指派給我的訂單', value: true},
              ]}
              onChange={(value) => filtersChange({my_assignment: value})}
              style={{marginBottom: 5}}
            />
            <OrderTable
              type={TableTypes.REVIEW}
              orders={orders}
              mounted={true}
              onUpdate={getOrders}
              pagination={{
                pageSize: filters.pageSize,
                current: filters.current,
                total,
              }}
              onPaging={(pagination) =>
                filtersChange({
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                })
              }
            />
          </div>
        </Block>
      </Wrapper>
    );
  } else if (uiState === UI_STATE.PRODUCTION) {
    return (
      <Wrapper>
        <Block style={{marginBottom: 20}}>
          <div>
            <div className="row">
              <h4 className="short">搜尋</h4>
              <Ant.Input.Search
                placeholder="訂單編號或購買者"
                onSearch={(str) => filtersChange({search: str})}
                allowClear
                style={{marginRight: 40}}
              />
              <h4 className="short">排序方式</h4>
              <Ant.Select
                value={filters.ordering}
                onChange={(nextValue) => {
                  filtersChange({ordering: nextValue, ...PAGINATION_INIT});
                }}>
                {[
                  {label: '更新時間 新到舊', value: '-updated'},
                  {label: '更新時間 舊到新', value: '+updated'},
                  {label: '急件時間', value: 'urgent_time'},
                ].map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            </div>
            <div className="row">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否為急件商品
                </h4>
                <Ant.Checkbox
                  checked={filters.is_urgent}
                  onChange={(e) =>
                    filtersChange({
                      is_urgent: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否為打樣商品
                </h4>
                <Ant.Checkbox
                  checked={filters.is_proof}
                  onChange={(e) =>
                    filtersChange({
                      is_proof: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <h4 className="short" style={{margin: '0 8px 0 0'}}>
                  是否使用編輯器
                </h4>
                <Ant.Checkbox
                  checked={filters.is_designed}
                  onChange={(e) =>
                    filtersChange({
                      is_designed: e.target.checked,
                      ...PAGINATION_INIT,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="row" style={{margin: 0}}>
            <div style={{fontWeight: '500', marginRight: 5}}>共</div>
            <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
            <div style={{fontWeight: '500', marginRight: 5}}>筆</div>

            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getOrders}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </Block>
        <Block>
          <div>
            <Tabs
              value={filters.display_state}
              onChange={(value) => filtersChange({display_state: value})}
              options={[
                {
                  label: '全部',
                  value:
                    'production_waiting,production_preparing,production_in_production,production_completed',
                },
                ...[
                  'production_waiting',
                  'production_preparing',
                  'production_in_production',
                  'production_completed',
                ].map((x) => ({label: ORDER_STATE[x], value: x})),
              ]}
              style={{marginBottom: 5}}
            />

            <OrderTable
              type={TableTypes.PRODUCTION}
              orders={orders}
              mounted={true}
              onUpdate={getOrders}
              pagination={{
                pageSize: filters.pageSize,
                current: filters.current,
                total,
              }}
              onPaging={(pagination) =>
                filtersChange({
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                })
              }
            />
          </div>
        </Block>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
